<template>
    <PlanoSaude :matricula="currentUser.matricula" />
</template>

<script lang="js">
    import PlanoSaude from '../../components/consulta/PlanoSaude.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { PlanoSaude }
    }

</script>