import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/unimed`;

class PlanoSaudeService {
    getUnimed(matricula, mes, ano) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/" + matricula + "/" + mes + "/" + ano);
    }

    getUnimedPdf(matricula, mes, ano) {
        return axios.post(API_BASE_URL + RESOURCE_V1 + "/chat-bot-link-pdf", {
            matricula: matricula,
            mes: mes,
            ano: ano,
            "valido": true
        });
    }
}

export default new PlanoSaudeService();